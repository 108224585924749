// Generated by Framer (2a8efd8)

import { addFonts, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["CGx6qOx99"];

const serializationHash = "framer-eNgC8"

const variantClassNames = {CGx6qOx99: "framer-v-1rct6o6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate1 = (_, t) => `translate(-50%, -50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "CGx6qOx99", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1rct6o6", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"CGx6qOx99"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", ...style}}><motion.div className={"framer-1ldofjh"} layoutDependency={layoutDependency} layoutId={"DIw4J8h8v"} style={{backgroundColor: "var(--token-5488df1e-d961-4aa1-8975-0c6275ea8cbd, rgb(1, 73, 75))"}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-a74d5571-7600-4ee3-9cdd-4c3cb1ff121d, rgb(255, 255, 255)))"}}>Button</motion.p></React.Fragment>} className={"framer-j0xihg"} layoutDependency={layoutDependency} layoutId={"c_PobTKDt"} style={{"--extracted-r6o4lv": "var(--token-a74d5571-7600-4ee3-9cdd-4c3cb1ff121d, rgb(255, 255, 255))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} transformTemplate={transformTemplate1} verticalAlignment={"top"} withExternalLayout/></motion.div></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = [".framer-eNgC8[data-border=\"true\"]::after, .framer-eNgC8 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-eNgC8.framer-92ka8o, .framer-eNgC8 .framer-92ka8o { display: block; }", ".framer-eNgC8.framer-1rct6o6 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 63px; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: 174px; }", ".framer-eNgC8 .framer-1ldofjh { align-content: center; align-items: center; bottom: 0px; display: flex; flex: none; flex-direction: row; flex-wrap: nowrap; gap: 10px; justify-content: center; left: 0px; overflow: hidden; padding: 24px 24px 24px 24px; position: absolute; right: 0px; top: 0px; z-index: 1; }", ".framer-eNgC8 .framer-j0xihg { flex: none; height: auto; left: 50%; position: absolute; top: 49%; white-space: pre; width: auto; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-eNgC8.framer-1rct6o6, .framer-eNgC8 .framer-1ldofjh { gap: 0px; } .framer-eNgC8.framer-1rct6o6 > *, .framer-eNgC8 .framer-1ldofjh > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-eNgC8.framer-1rct6o6 > :first-child, .framer-eNgC8 .framer-1ldofjh > :first-child { margin-left: 0px; } .framer-eNgC8.framer-1rct6o6 > :last-child, .framer-eNgC8 .framer-1ldofjh > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 63
 * @framerIntrinsicWidth 174
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Framerq1UPMEkUk: React.ComponentType<Props> = withCSS(Component, css, "framer-eNgC8") as typeof Component;
export default Framerq1UPMEkUk;

Framerq1UPMEkUk.displayName = "bau";

Framerq1UPMEkUk.defaultProps = {height: 63, width: 174};

addFonts(Framerq1UPMEkUk, [])